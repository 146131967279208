<template>
    <div class="card pt-3 h-100 u-flex u-flex-column">
        <div class="content">
            <img class="u-circle" :src="avatarSrc" />
            <h6 class="title text-violet-finplex">{{ nameTitle }}</h6>
            <p>{{ description }}</p>
        </div>
        <div class="card__action-bar u-text-center">
            Entre em contato comigo:<br />
            <em>{{ email }}</em>
        </div>
        <div class="card__action-bar u-center">
            <a :href="instagramLink()" target="_blank">
                <span class="fa-stack fa-2x">
                    <i class="fas fa-circle fa-stack-2x text-violet-finplex"></i>
                    <i class="fab fa-instagram fa-stack-1x fa-inverse"></i>
                </span>
            </a>
            <a :href="facebookLink()" target="_blank">
                <span class="fa-stack fa-2x">
                    <i class="fas fa-circle fa-stack-2x text-violet-finplex"></i>
                    <i class="fab fa-facebook-f fa-stack-1x fa-inverse"></i>
                </span>
            </a>
            <a :href="linkedinLink()" target="_blank">
                <span class="fa-stack fa-2x">
                    <i class="fas fa-circle fa-stack-2x text-violet-finplex"></i>
                    <i class="fab fa-linkedin-in fa-stack-1x fa-inverse"></i>
                </span>
            </a>
            <a :href="whatsappLink()" target="_blank">
                <span class="fa-stack fa-2x">
                    <i class="fas fa-circle fa-stack-2x text-success"></i>
                    <i class="fab fa-whatsapp fa-stack-1x fa-inverse"></i>
                </span>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "MemberComponent",
    props: {
        avatarSrc: String,
        nameTitle: String,
        description: String,
        email: String,
        instagramUsername: String,
        facebookUsername: String,
        linkedinUsername: String,
        whatsappNumber: String,
    },
    methods: {
        instagramLink() {
            return `https://www.instagram.com/${this.instagramUsername}`;
        },
        facebookLink() {
            return `https://www.facebook.com/${this.facebookUsername}`;
        },
        linkedinLink() {
            return `https://www.linkedin.com/in/${this.linkedinUsername}`;
        },
        whatsappLink() {
            return `https://wa.me/${this.whatsappNumber}`;
        },
    },
};
</script>
