<template>
    <div class="timeline py-16">
        <Step
            v-for="(step, index) in this.steps"
            :key="index"
            :direction="index % 2 === 0 ? 'left' : 'right'"
            v-bind="step"
        />
    </div>
</template>

<script>
import Step from "./Step.vue";
export default {
    name: "TimelineComponent",
    components: {
        Step,
    },
    props: {
        steps: Array,
    },
};
</script>

<style scoped>
/* https://www.w3schools.com/howto/howto_css_timeline.asp */

/* The actual timeline (the vertical ruler) */
.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
    content: "";
    position: absolute;
    width: 4px;
    background-color: #1e5898;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 767px) {
    /* Place the timelime to the left */
    .timeline::after {
        left: 31px;
    }
}
</style>
