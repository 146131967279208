<template>
    <section class="section" id="why">
        <div class="w-80 pt-16 pb-8 u-center">
            <div class="row u-center">
                <div class="col-5">
                    <img src="/assets/why_1.jpg" />
                </div>
                <div class="col-1"></div>
                <div class="col-6">
                    <h2 class="mark-left text-violet-finplex">{{ why }}</h2>
                    <div class="divider"></div>
                    <ul class="no-bullets">
                        <li class="font-alt font-light" v-for="(reason, index) in this.reasons" :key="index">
                            <i class="fas fa-angle-double-right"></i> {{ reason }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="w-80 pt-8 pb-16 u-center">
            <div class="row u-center">
                <div class="col-5">
                    <h2 class="mark-left text-violet-finplex">{{ how }}</h2>
                    <div class="divider"></div>
                    <ul class="no-bullets">
                        <li class="font-alt font-light" v-for="(task, index) in this.tasks" :key="index">
                            <i class="fas fa-check-square text-green-500"></i> {{ task }}
                        </li>
                    </ul>
                </div>
                <div class="col-1"></div>
                <div class="col-6">
                    <img src="/assets/why_2.jpg" />
                </div>
            </div>
        </div>
        <div id="why-prlx-img" class="hero hero-img parallax-img py-5">
            <div class="hero-body">
                <div class="w-90">
                    <div class="row u-center">
                        <div class="col-lg-7"></div>
                        <div class="col-lg-5">
                            <h1 class="u-text-right mark-right text-violet-finplex">
                                {{ parallaxMessage }}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "WhyComponent",
    data() {
        return {
            why: "Por que ter um Planejador Financeiro?",
            reasons: [
                "Você trabalha mas nunca sobra dinheiro.",
                "Você trabalha para tentar pagar todas as contas.",
                "Você vive entrando no cheque especial, empréstimos e cartões de crédito.",
                "Você não tem e/ou nem sabe o que é reserva de emergência.",
                "Você acredita que a solução da sua falta de dinheiro será ter mais dinheiro.",
                "Você vive culpando terceiros por seu insucesso financeiro.",
                "Você tem sonhos e não consegue realizá-los.",
                "Você sente que precisa de aconselhamento profissional em finanças.",
            ],
            how: "Como um Planejador Financeiro pode te ajudar?",
            tasks: [
                "Por meio da coleta de seus dados financeiros, organização e análise, propor sugestões financeiras para sua idade e momento de vida.",
                "Juntos, vamos buscar a eficiência financeira para que sobre mais dinheiro ao final do mês para você.",
                "Tranquilidade e saúde financeira para tomar melhores decisões com foco em qualidade de vida.",
                "Na realização planejada de sonhos, começando a investir e vendo seu dinheiro trabalhando para você.",
            ],
            parallaxMessage: "Eficiência Financeira, Segurança e Tranquilidade",
        };
    },
};
</script>

<style scoped>
#why-prlx-img {
    background: url(/assets/why_3.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}
</style>