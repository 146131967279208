<template>
    <div class="card h-100 u-flex u-flex-column">
        <div class="content py-3">
            <h6 class="title u-text-left text-violet-finplex">{{ this.title }}</h6>
            <div class="divider"></div>
            <p class="font-light small u-text-justify font-thin description">{{ this.description }}</p>
            <div class="divider"></div>
            <ul class="menu">
                <li class="menu-item" v-for="(detail, index) in details" :key="index">
                    <span class="icon text-violet-finplex"
                        ><i class="fa fa-wrapper fa-check small" aria-hidden="true"></i></span
                    >{{ detail }}
                </li>
            </ul>
            <div class="space"></div>
            <a v-if="this.actionBtn" :href="this.actionBtn.href">
                <button class="btn--pilled bg-violet-finplex text-light">{{ this.actionBtn.text }}</button>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "ServiceComponent",
    props: {
        title: String,
        description: String,
        details: Array,
        actionBtn: Object,
    },
};
</script>

<style scoped>
.description {
    font-size: small;
}
.content {
    margin: 0 auto 0em;
}
</style>
