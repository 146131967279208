<template>
    <HeaderComponent />
    <Hero />
    <Why />
    <How />
    <What />
    <Who />
    <LGPD />
    <FooterComponent />
</template>

<script>
import HeaderComponent from "./Home/Header.vue";
import Hero from "./Home/Hero.vue";
import Why from "./Home/Why.vue";
import How from "./Home/How.vue";
import What from "./Home/What.vue";
import Who from "./Home/Who.vue";
import LGPD from "./Home/LGPD.vue";
import FooterComponent from "./Home/Footer.vue";

export default {
    name: "HomePage",
    components: {
        HeaderComponent,
        Hero,
        Why,
        How,
        What,
        Who,
        LGPD,
        FooterComponent,
    },
};
</script>

<style>
.text-violet-finplex {
    border-color: #3e4095!important;
    color: #3e4095!important;
}
.text-orange-finplex {
    border-color: #f58634!important;
    color: #f58634!important;
}
.bg-violet-finplex {
    background-color: #3e4095!important;
}
.bg-orange-finplex {
    background-color: #f58634!important;
}
.mark-left {
    border-left: 10px solid;
    padding-left: 10px;
}
.mark-right {
    border-right: 10px solid;
    padding-right: 10px;
}
</style>
