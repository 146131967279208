<template>
    <section class="section bg-gray-100 u-center" id="what">
        <div class="w-80 py-16">
            <div class="row">
                <div class="col u-text-center">
                    <h1>Nossos Serviços</h1>
                    <h6 class="font-alt font-light">
                        O que fazemos para te ajudar.
                    </h6>
                </div>
            </div>
            <div class="row">
                <div class="col-4" v-for="(service, index) in services" :key="index">
                    <Service v-bind="service" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Service from "./What/Service.vue";

export default {
    name: "WhatComponent",
    components: {
        Service,
    },
    data() {
        return {
            services: [
                {
                    title: "Consultoria Financeira",
                    description:
                        "Construção de um plano de ação completo, que leva em conta seu perfil, suas necessidades e seus objetivos de vida. Reuniões que proporcionam o crescimento pessoal por meio do entendimento de conceitos de organização financeira, do funcionamento das finanças e dos investimentos.",
                    details: [
                        "Organização financeira",
                        "Mapeamento de consumo",
                        "Gestão de dívidas",
                        "Aposentadoria e previdência privada",
                        "Gestão de riscos e seguros",
                        "Educação financeira",
                    ],
                    actionBtn: {
                        href: "#contact",
                        text: "Entre em contato",
                    },
                },
                {
                    title: "Consultoria de Investimentos",
                    description:
                        "Consultoria estratégica para avaliação do horizonte de investimentos e composição de portfólios utilizando a metodologia Goal Based Investment, onde são considerados seus objetivos de vida e seu perfil de investidor, buscando a realização de sonhos, estabilidade e proteção financeira.",
                    details: [
                        "Análise do perfil de investidor",
                        "Montagem de carteiras de investimento",
                        "Otimização de investimentos",
                        "Acompanhamento de carteiras",
                        "Recomendação de acordo com perfil",
                        "Educação para investir",
                    ],
                    actionBtn: {
                        href: "#contact",
                        text: "Entre em contato",
                    },
                },
                {
                    title: "Assessoria Tributária para Investidores",
                    description:
                        "Assessoria com a finalidade de promover a apuração dos resultados em investimentos de renda variável do mercado de bolsa de valores, emissão de DARF, entrega de relatório e auxílio no preenchimento da declaração de ajuste anual de pessoa física.",
                    details: [
                        "Acompanhamento de investimentos",
                        "Acompanhamento mensal ou anual/pontual",
                        "Apuração de Lucro Tributável ou Prejuízo",
                        "Relatório de resultados do exercício",
                        "Relatório auxiliar para declaração de IRPF",
                        "Educação para declarar o IRPF",
                    ],
                    actionBtn: {
                        href: "#contact",
                        text: "Entre em contato",
                    },
                },
            ],
        };
    },
};
</script>
