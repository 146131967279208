<template>
    <footer class="footer">
        <h6 class="footer__title white uppercase">Finplex</h6>
        <div class="content">
            <div class="divider"></div>
            <div class="row">
                <div class="col-4 offset-1">
                    <ul class="no-bullets">
                        <li class="footer__list-title">Social</li>
                        <div class="row u-center" style="min-height: 80px">
                            <div class="col">
                                <a href="https://www.instagram.com/finplex">
                                    <span class="icon">
                                        <i class="fab fa-instagram large" aria-hidden="true"></i>
                                    </span>
                                </a>
                            </div>
                            <div class="col">
                                <a href="https://www.facebook.com/finplexoficial">
                                    <span class="icon">
                                        <i class="fab fa-facebook-f large" aria-hidden="true"></i>
                                    </span>
                                </a>
                            </div>
                            <div class="col">
                                <a href="https://linkedin.com/in/finplexoficial">
                                    <span class="icon">
                                        <i class="fab fa-linkedin-in large" aria-hidden="true"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </ul>
                </div>
                <div class="col-5 offset-1">
                    <ul class="no-bullets">
                        <li class="footer__list-title">Certificações / Formações</li>
                        <div class="row u-center">
                            <div class="col" v-for="(stamp,index) in stamps" :key="index">
                                <div class="tooltip u-center" :data-tooltip="stamp.description" style="min-height: 70px">
                                    <img class="img-stretch logo-stamp" :src="stamp.imgSrc" />
                                </div>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
            <div class="row u-center py-6">
                <div class="col-3 u-center">
                    <img class="img-stretch logo-cvm" src="/assets/cvm_logo.png" />
                </div>
                <div class="col-9">
                    <p class="small font-thin u-text-justify text-light about">
                        A Finplex Planejamento Financeiro Ltda, inscrita sob o CNPJ: 43.242.319/0001-36, é uma empresa
                        com foco em Planejamento Financeiro e conta com profissionais devidamente habilitados e autorizados
                        pela CVM para o exercício da atividade de Consultoria de Investimentos. As informações contidas
                        neste website são de caráter exclusivamente informativo. E-mail:
                        <b>contato@finplex.com.br</b>.
                    </p>
                </div>
            </div>
        </div>
        <p class="subtitle">
            2019 - {{ getCurrentYear() }} <i class="far fa-registered"></i> Finplex Planejamento Financeiro LTDA<br />CNPJ
            43.242.319/0001-36
        </p>
    </footer>
</template>

<script>
export default {
    name: "FooterComponent",
    data() {
        return {
            stamps: [
                {
                    imgSrc: "/assets/cea.png",
                    description: "Certificação ANBIMA de Especialista em Investimentos",
                },
                {
                    imgSrc: "/assets/cpa_20.jpeg",
                    description: "Certificação ANBIMA para distribuição de produtos de investimento para clientes dos segmentos varejo alta renda, private, corporate e investidores institucionais",
                },
                {
                    imgSrc: "/assets/fbb_700.png",
                    description: "Certificação PLDFT – Prevenção à Lavagem de Dinheiro e ao Financiamento do Terrorismo",
                },
                {
                    imgSrc: "/assets/pucrs.png",
                    description: "Pós-Graduação em Finanças, Investimentos e Banking pela PUCRS",
                },
            ],
        }
    },
    methods: {
        getCurrentYear() {
            return new Date().getFullYear();
        },
    },
};
</script>

<style scoped>
.footer {
    margin-top: 0;
}
.about {
    line-height: 1.1;
    font-size: small;
    margin-bottom: initial;
}
.logo-cvm {
    max-width: 7rem;
}
.logo-stamp {
    max-height: 5rem;
}
.tooltip::after {
    text-overflow: initial;
    max-width: initial;
}
</style>
