<template>
    <section class="section" id="lgpd">
        <div class="content">
            <div class="tile u-items-center bg-violet-finplex p-2 u-round">
                <div class="tile__container u-overflow-hidden">
                    <p class="tile__title m-0 u-text-no-wrap"></p>
                    <p class="tile__subtitle m-0 text-light font-light">{{ message }}</p>
                </div>
                <div class="tile__buttons m-0">
                    <button class="btn-success btn-small uppercase" @click="closeLGPD">Estou ciente</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "LGPDComponent",
    data() {
        return {
            message:
                "Utilizamos cookies essenciais e tecnologias semelhantes e, ao continuar navegando, você concorda com estas condições.",
        };
    },
    methods: {
        closeLGPD() {
            document.querySelector("#lgpd").style.display = "none";
        },
    },
};
</script>

<style>
#lgpd .tile {
    position: fixed;
    bottom: 0;
    z-index: 1024;
}
</style>
