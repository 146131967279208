<template>
    <div
        class="header header-fixed u-unselectable header-animated"
        :class="{ 'header-clear': this.shouldBeHeaderClear }"
    >
        <div class="header-brand">
            <div class="nav-item no-hover">
                <img src="https://imgur.com/YNQaVh9.png" />
            </div>
            <div class="nav-item nav-btn">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>

        <div class="header-nav">
            <div class="nav-left"></div>
            <div class="nav-center"></div>

            <div class="nav-right uppercase">
                <div class="nav-item">
                    <a href="#why">Por quê</a>
                </div>
                <div class="nav-item">
                    <a href="#how">Metodologia</a>
                </div>
                <div class="nav-item">
                    <a href="#what">Serviços</a>
                </div>
                <div class="nav-item">
                    <a href="#who">Quem Somos</a>
                </div>
                <div class="nav-item text-center">
                    <a href="https://www.instagram.com/finplex">
                        <span class="icon">
                            <i class="fab fa-instagram large" aria-hidden="true"></i>
                        </span>
                    </a>
                </div>
                <div class="nav-item text-center">
                    <a href="https://www.facebook.com/finplexoficial">
                        <span class="icon">
                            <i class="fab fa-facebook-f large" aria-hidden="true"></i>
                        </span>
                    </a>
                </div>
                <div class="nav-item text-center">
                    <a href="https://linkedin.com/in/finplexoficial">
                        <span class="icon">
                            <i class="fab fa-linkedin-in large" aria-hidden="true"></i>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HeaderComponent",
    data() {
        return {
            shouldBeHeaderClear: true,
        };
    },
    mounted() {
        document.addEventListener("scroll", this.onScrollDocument);
        this.getNavBtn().addEventListener("click", this.onClickNavBtn);
    },
    methods: {
        onScrollDocument() {
            this.shouldBeHeaderClear = window.pageYOffset < 100 && this.isActiveHeaderNav() === false;
        },
        getNavBtn() {
            return document.querySelector(".nav-btn");
        },
        getHeaderNav() {
            return document.querySelector(".header-nav");
        },
        isActiveHeaderNav() {
            return this.getHeaderNav().classList.contains("active");
        },
        onClickNavBtn() {
            this.getNavBtn().classList.toggle("active");
            this.getHeaderNav().classList.toggle("active");

            if (this.isActiveHeaderNav()) {
                this.shouldBeHeaderClear = false;
            }
        },
    },
};
</script>

<style scoped>
.header-brand .nav-item img {
    width: 7rem;
}
@media screen and (max-width: 767px) {
    .header {
        padding: 0;
    }
}
</style>
