<template>
    <section class="section">
        <div id="hero-img" class="hero fullscreen hero-img parallax-img">
            <div class="hero-body u-center">
                <div class="w-90">
                    <div class="row">
                        <div class="col-4">
                            <h1 class="text-violet-finplex">
                                Planejamento Financeiro Pessoal e Personalizado
                            </h1>
                            <h6 class="font-alt font-light">
                                Transformamos a complexidade das Finanças e
                                Investimentos em soluções simples para te ajudar
                                de maneira única e personalizada.
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "HeroComponent",
};
</script>

<style>
#hero-img {
    background: url(/assets/hero.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}
</style>
