<template>
    <section class="sectio py-16" id="who">
        <div class="w-70 u-center">
            <div class="row u-center">
                <div class="col-4 my-5">
                    <img src="https://imgur.com/ZayvYQB.png" />
                </div>
                <div class="col-7 offset-1 u-text-right">
                    <h1 class="mark-right text-violet-finplex">Quem somos</h1>
                    <div class="divider"></div>
                    <h5 class="font-alt font-light">
                        A Finplex é uma empresa focada em Finanças e Investimentos, auxiliando pessoas com serviços de
                        planejamento financeiro pessoal e familiar, consultoria de investimentos, gestão de riscos e
                        seguros, planejamento fiscal, de aposentadoria e sucessório, bem como o acompanhamento e
                        apuração de investimentos para fins de imposto sobre a renda.
                    </h5>
                </div>
            </div>
        </div>
    </section>
    <section class="section py-5 bg-gray-100">
        <div class="w-80 u-center">
            <div class="row u-text-center">
                <div class="col-lg-3 text-violet-finplex" v-for="(value,index) in values" :key="index">
                    <h3 class="font-light" v-html="value"></h3>
                </div>
            </div>
        </div>
    </section>
    <section class="section py-16" id="contact">
        <div class="content">
            <div class="row">
                <div class="col-6 my-2" v-for="(member, index) in team" :key="index">
                    <Member v-bind="member" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Member from "./Who/Member.vue";

export default {
    name: "WhoComponent",
    components: {
        Member,
    },
    data() {
        return {
            values: [
                '<b>F</b>inanças',
                '<b>In</b>vestimentos',
                '<b>Pl</b>anejamento',
                '<b>Ex</b>pertise',
            ],
            team: [
                {
                    avatarSrc: "https://i.imgur.com/07B6UsF.jpg",
                    nameTitle: "William Brizola Lisboa, CEA",
                    description: "Especialista em Investimentos - Anbima, Consultor de Investimentos - CVM",
                    email: "william@finplex.com.br",
                    instagramUsername: "williamblisboa",
                    facebookUsername: "william.brizolalisboa",
                    linkedinUsername: "williamlisboa",
                    whatsappNumber: "555499939738",
                },
                {
                    avatarSrc: "https://i.imgur.com/yI6qe5G.jpg",
                    nameTitle: "Vinícius Martins Knob, CEA",
                    description: "Especialista em Investimentos - Anbima, Pós Graduado em Finanças, Investimentos e Banking pela PUCRS",
                    email: "vinicius@finplex.com.br",
                    instagramUsername: "knob.vinicius",
                    facebookUsername: "viniciusknob",
                    linkedinUsername: "viniciusknob",
                    whatsappNumber: "555499744166",
                },
            ],
        };
    },
};
</script>

<style></style>
