<template>
  <RootComponent />
</template>

<script>
  import RootComponent from './components/Home.vue'

  export default {
    name: 'App',
    components: {
      RootComponent
    }
  }
</script>
