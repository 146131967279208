<template>
    <section class="section u-center" id="how">
        <div class="w-80 py-16">
            <div class="row">
                <div class="col-12 u-text-center">
                    <h1>Nossa Metodologia</h1>
                    <h6 class="font-alt font-light">
                        Como funciona nossa interação com você.
                    </h6>
                </div>
            </div>
            <Timeline :steps="steps" />
        </div>
    </section>
</template>

<script>
import Timeline from "./How/Timeline.vue";

export default {
    name: "HowComponent",
    components: {
        Timeline,
    },
    data() {
        return {
            steps: [
                {
                    iconClass: "fa-mobile-alt",
                    name: "Contato",
                    description:
                        "Vamos conversar? Qual é o seu sonho? O que você deseja realizar ou alcançar em sua vida? Qual é a sua necessidade?",
                },
                {
                    iconClass: "fa-chart-bar",
                    name: "Diagnóstico financeiro",
                    description:
                        "Em uma reunião com um planejador financeiro, serão coletadas informações para compreender o caminho a ser traçado para que você alcance seus objetivos.",
                },
                {
                    iconClass: "fa-cogs",
                    name: "Análise",
                    description:
                        "Neste momento, focamos especialmente no que irá ajudar você a conquistar o que deseja e quais serão os passos realizados durante o planejamento financeiro.",
                },
                {
                    iconClass: "fa-handshake",
                    name: "Aplicação",
                    description:
                        "Comunicaremos as recomendações e, caso sejam aceitas, iniciaremos a fase prática, onde todas as recomendações levantadas serão executadas. Não se preocupe! Estaremos juntos com você.",
                },
                {
                    iconClass: "fa-chart-line",
                    name: "Acompanhamento",
                    description:
                        "Seu planejador financeiro estará disponível para tirar suas dúvidas e acompanhar a evolução das metas no seu dia-a-dia pelos canais de comunicação disponíveis.",
                },
                {
                    iconClass: "fa-comments",
                    name: "Reuniões",
                    description:
                        "Periodicamente, faremos reuniões para monitoramento do caminho planejado, dos sonhos determinados, e também para promover a sua educação financeira.",
                },
            ],
        };
    },
};
</script>
