<template>
    <div class="timeline__container" :class="direction">
        <div class="tile p-3 u-round bg-gray-100">
            <div class="tile__container">
                <h6 class="tile__title mb-2 text-violet-finplex">{{ name }}</h6>
                <p class="tile__subtitle font-alt font-light">{{ description }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "StepComponent",
    props: {
        direction: String,
        name: String,
        description: String,
    },
};
</script>

<style scoped>
/* https://www.w3schools.com/howto/howto_css_timeline.asp */

/* Container around content */
.timeline__container {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
}

/* The circles on the timeline */
.timeline__container::after {
    content: "";
    position: absolute;
    width: 2rem;
    height: 2rem;
    right: -15px;
    background-color: #1e5898;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}

.timeline__container::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    border: medium solid lightgray;
}

/* Place the container to the left */
.timeline__container.left {
    left: 0;
}

/* Place the container to the right */
.timeline__container.right {
    left: 50%;
}

/* Add arrows to the left container (pointing right) */
.timeline__container.left::before {
    right: 25px;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent lightgray;
}

/* Add arrows to the right container (pointing left) */
.timeline__container.right::before {
    left: 25px;
    border-width: 10px 10px 10px 0;
    border-color: transparent lightgray transparent transparent;
}

/* Fix the circle for containers on the right side */
.timeline__container.right::after {
    left: -17px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 767px) {

    /* Full-width containers */
    .timeline__container {
        width: 100%;
        padding-left: 70px;
        padding-right: 25px;
    }

    /* Make sure that all arrows are pointing leftwards */
    .timeline__container::before {
        left: 55px !important;
        border-width: 10px 10px 10px 0 !important;
        border-color: transparent lightgray transparent transparent !important;
    }

    /* Make sure all circles are at the same spot */
    .timeline__container.left::after,
    .timeline__container.right::after {
        left: 14px;
    }

    /* Make all right containers behave like the left ones */
    .timeline__container.right {
        left: 0%;
    }
}
</style>
